import type { Database } from '@lib/database.types'
import type { SupabaseClient } from '@supabase/supabase-js'
import { useQuery } from '@tanstack/react-query'
import { createClient } from 'utils/supabase/component'

export const getUserProfile = async (supabase: SupabaseClient<Database>) => {
  const user = await supabase.auth.getUser()
  if (!user.data.user?.id) return null
  
  // First get the profile data
  const profileResult = await supabase
    .from('profiles')
    .select(`
      user_id, 
      email, 
      phone, 
      first_name, 
      last_name, 
      avatar_url, 
      banner_url, 
      additional_info
    `)
    .eq('user_id', user.data.user.id)
    .single()

  if (profileResult.error) {
    console.error(profileResult.error)
    return null
  }

  // Then get the permissions from the view
  const permissionsResult = await supabase
    .from('user_permissions_view')
    .select('permissions')
    .eq('user_id', user.data.user.id)
    .single()

  return {
    ...profileResult.data,
    permissions: permissionsResult.data?.permissions || [],
  }
}

export const useUserProfile = () => {
  const supabase = createClient()
  return useQuery({ queryKey: ['userProfile'], queryFn: () => getUserProfile(supabase) })
}

export const getUser = async (supabase: SupabaseClient<Database>) => {
  const { data, error } = await supabase.auth.getUser()
  if (error) {
    console.error(error)
    return null
  }
  return data.user
}

export const useUser = () => {
  const supabase = createClient()
  return useQuery({ queryKey: ['user'], queryFn: () => getUser(supabase) })
}

export const getUsers = async (supabase: SupabaseClient<Database>) => {
  const { data, error } = await supabase.from('profiles').select('user_id, email, first_name, last_name, avatar_url')
  if (error) throw new Error(error.message)
  return data
}

export const useUsers = () => {
  const supabase = createClient()
  return useQuery({ queryKey: ['users'], queryFn: () => getUsers(supabase) })
}

export const getUserById = async (supabase: SupabaseClient<Database>, id: string) => {
  const { data, error } = await supabase.from('profiles').select('user_id, email, first_name, last_name, avatar_url, banner_url, phone, additional_info, trainer (qualification, email, phone, additional_info, banner_url, avatar_url), boardmember:boardmembers (email, phone, additional_info, banner_url, avatar_url, x_handle, instagram_handle, facebook_link)').eq('user_id', id).single()
  if (error) throw new Error(error.message)
  return data
}

export const useUserById = (id: string) => {
  const supabase = createClient()
  return useQuery({ queryKey: ['user', id], queryFn: () => getUserById(supabase, id) })
}

export const useHasPermission = (permissionName: string) => {
  const { data: userProfile } = useUserProfile()
  return userProfile?.permissions.includes(permissionName) ?? false
}

export const getUserPermissions = async (supabase: SupabaseClient<Database>) => {
  const user = await supabase.auth.getUser()
  if (!user.data.user?.id) return null

  const { data, error } = await supabase
    .from('user_permissions_view')
    .select('permissions')
    .eq('user_id', user.data.user.id)
    .single()

  if (error) {
    console.error(error)
    return null
  }

  return data.permissions || []
}

export const useUserPermissions = () => {
  const supabase = createClient()
  return useQuery({ 
    queryKey: ['userPermissions'], 
    queryFn: () => getUserPermissions(supabase),
  })
}
