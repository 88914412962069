import { mergeAttributes, Node } from '@tiptap/core'
import { ReactNodeViewRenderer } from '@tiptap/react'
import { FigureImageComponent } from './FigureImageComponent'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    figureImage: {
      setFigureImage: (options: { src: string, caption?: string }) => ReturnType
    }
  }
}

export const FigureImage = Node.create({
  name: 'figureImage',
  
  group: 'block',
  
  atom: true,

  addAttributes() {
    return {
      src: {
        default: null,
      },
      caption: {
        default: '',
      },
      isTemp: {
        default: true,
      },
      originalName: {
        default: '',
      },
    }
  },

  parseHTML() {
    return [
      {
        tag: 'figure',
        getAttrs: dom => {
          const element = dom
          const img = element.querySelector('img')
          const figcaption = element.querySelector('figcaption')
          
          return {
            src: img?.getAttribute('src'),
            caption: figcaption?.textContent,
          }
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    const { src, caption, ...attrs } = HTMLAttributes
    return ['figure', mergeAttributes(attrs), [
      'img', { src, alt: caption || '' },
      ['figcaption', caption || ''],
    ]]
  },

  addNodeView() {
    return ReactNodeViewRenderer(FigureImageComponent)
  },

  addCommands() {
    return {
      setFigureImage:
        options =>
        ({ commands, chain }) => {
          // Return the chain to allow for command chaining
          return chain()
            .insertContent({
              type: this.name,
              attrs: {
                ...options,
                isTemp: true,
                originalName: '',
              },
            })
            .run()
        },
    }
  },
}) 