'use client'
import { Box, useDisclosure } from "@chakra-ui/react"
import Picker from "@components/Picker/Picker"
import { MotionBox } from "@components/motion"
import type { NavigationStoryblok } from "@lib/storyblok.types"
import { IconlyMenu } from "assets/Iconly-Menu-Icon"
import type { Variants } from "framer-motion"
import { useEffect, useRef, useState } from "react"
import { Sheet } from 'react-modal-sheet'


const variants: Variants = {
  open: {
    width: '250px',
    height: '250px',
    color: '#e74c3c',
    transition: {
      type: 'spring',
      stiffness: 260,
      damping: 20,
    },
  },
  closed: {
    color: 'black',
    width: '66.66px',
    height: '66.66px',
    transition: {
      type: 'spring',
      stiffness: 287,
      damping: 27,
    },
  },
}

interface IProps {  
  blok: NavigationStoryblok
}

const MobileNav: React.FC<IProps> = ({blok}) => {
  const {onToggle, isOpen, onClose, onOpen} = useDisclosure()
  const constraintsRef = useRef<HTMLDivElement>(null)
  const [isDragging, setIsDragging] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)
  const [flattenedLinks, setFlattenedLinks] = useState<any[]>([])
  const [selectedValue, setSelectedValue] = useState<string>("Option 3")

  useEffect(() => {
    const flattenLinks = (links: any[]): any[] => {
      return links.reduce((acc, link) => {
        if (link.children && link.children.length > 0) {
          // If the link has children, only add the children
          acc.push(...flattenLinks(link.children))
        } else {
          // If the link has no children, add the link itself
          acc.push(link)
        }
        return acc
      }, [])
    }

    setFlattenedLinks(flattenLinks(blok.nav_links!))
  }, [blok.nav_links])

  return (
    <>
      <Sheet isOpen={isOpen} onClose={() => onClose()} detent="content-height">
        <Sheet.Container>
          <Sheet.Header/>
          <Sheet.Content>
            <Sheet.Scroller>
              <Box>
                <Picker
                  options={["Option 1", "Option 2", "Option 3", "Option 4", "Option 5"]}
                  selectedValue={selectedValue}
                  onChange={(value) => setSelectedValue(value)}
                />
              </Box>
            </Sheet.Scroller>
          </Sheet.Content>
        </Sheet.Container>
        <Sheet.Backdrop/>
      </Sheet>
      <Box pos="fixed" zIndex={isDragging ? 9999 : 0} bottom="25" right="25" top="25" left="25" ref={constraintsRef}/>
      <MotionBox
        onDragStart={() => setIsDragging(true)}
        onDragEnd={() => setIsDragging(false)}
        boxShadow={`
          0px 1.6px 2.2px rgba(0, 0, 0, 0.02),
          0px 3.8px 5.3px rgba(0, 0, 0, 0.028),
          0px 7.1px 10px rgba(0, 0, 0, 0.035),
          0px 12.7px 17.9px rgba(0, 0, 0, 0.042),
          0px 23.8px 33.4px rgba(0, 0, 0, 0.05),
          0px 57px 80px rgba(0, 0, 0, 0.07)`}
        drag
        dragConstraints={constraintsRef}
        bg="white"
        cursor="pointer"
        onClick={() => {
          if(isDragging) return
          onOpen()
        }}
        pos="fixed"
        bottom="25"
        right="25"
        borderRadius="xl"
        zIndex={9999999}
        onAnimationStart={() => setIsAnimating(true)}
        onAnimationComplete={() => setIsAnimating(false)}
        width="66.66px"
        height="66.66px"
      >
        <Box
          pos="absolute"
          bottom="2"
          right="2"
          top={!isOpen && !isAnimating ? "2" : "unset"}
          left={!isOpen && !isAnimating ? "2" : "unset"}
        >
          <IconlyMenu size="100%" style={{width: "calc(66.66px - (var(--chakra-space-2) * 2))"}}/>
        </Box>
      </MotionBox>
    </>
  )
}

export default MobileNav