import { Box, Button, ButtonGroup, Flex, IconButton, Input, InputGroup, InputRightElement, Menu, MenuButton, MenuItem, MenuList, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger } from '@chakra-ui/react'
import type { Editor } from '@tiptap/react'
import type React from 'react'
import { BsCardImage } from 'react-icons/bs'
import { FiAlignCenter, FiAlignJustify, FiAlignLeft, FiAlignRight, FiBold, FiChevronDown, FiItalic, FiLink, FiMenu, FiRotateCcw, FiRotateCw, FiUnderline } from 'react-icons/fi'
import { LuHeading1, LuHeading2, LuHeading3 } from 'react-icons/lu'

interface IEditorMenuProps {
  enabledModules: TModule[]
  showBorderBottom?: boolean
  editor?: Editor | null
}

export type TModule = 'history' | 'format' | 'heading' | 'link' | 'textAlign' | 'image' | 'figure' | 'all'

const EditorMenu: React.FC<IEditorMenuProps> = ({ enabledModules, showBorderBottom, editor }) => {
  if (!editor) return null

  const modules: Array<{ moduleName: TModule, module: (key: string, hideDivider: boolean) => JSX.Element }> = [
    {
      moduleName: 'history',
      module: (key, hideDivider) => (
        <Box key={key}>
          <ButtonGroup
            size="sm"
            isAttached
            variant="ghost"
          >
            <IconButton size="sm" icon={<FiRotateCcw/>} aria-label="undo" onClick={() => editor.chain().focus().undo().run()}/>
            <IconButton size="sm" icon={<FiRotateCw/>} aria-label="redo" onClick={() => editor.chain().focus().redo().run()}/>
          </ButtonGroup>
          {hideDivider && (
            <Box borderRight="1px solid" borderRightColor="#e4e9ed"/>
          )}
        </Box>
      ),
    },
    {
      moduleName: 'format',
      module: (key, hideDivider) => (
        <Box key={key}>
          <ButtonGroup
            size="sm"
            isAttached
            variant="ghost"
          >
            <IconButton
              icon={<FiBold/>}
              isActive={editor.isActive('bold')}
              aria-label="bold"
              onClick={() => editor.chain().focus().toggleBold().run()}
              size="sm"
            />
            <IconButton
              icon={<FiItalic/>}
              isActive={editor.isActive('italic')}
              aria-label="italic"
              onClick={() => editor.chain().focus().toggleItalic().run()}
              size="sm"
            />
            <IconButton
              icon={<FiUnderline/>}
              isActive={editor.isActive('underline')}
              aria-label="underline"
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              size="sm"
            />
          </ButtonGroup>
          {hideDivider && (
            <Box borderRight="1px solid" borderRightColor="#e4e9ed"/>
          )}
        </Box>
      ),
    },
    {
      moduleName: 'heading',
      module: (key, hideDivider) => (
        <Box key={key}>
          <Menu>
            {({ isOpen }) => (
              <>
                <MenuButton isActive={isOpen} as={Button} rightIcon={<FiChevronDown/>} size="sm" variant="ghost">{editor.isActive('heading') ? `Überschrift ${editor.getAttributes('heading').level}` : 'Absatz'}</MenuButton>
                <MenuList>
                  <MenuItem icon={<FiMenu/>} onClick={() => { editor.chain().focus().setParagraph().run() }}>Absatz</MenuItem>
                  <MenuItem icon={<LuHeading1/>} onClick={() => { editor.chain().focus().toggleHeading({ level: 1 }).run() }}>Überschrift 1</MenuItem>
                  <MenuItem icon={<LuHeading2/>} onClick={() => { editor.chain().focus().toggleHeading({ level: 2 }).run() }}>Überschrift 2</MenuItem>
                  <MenuItem icon={<LuHeading3/>} onClick={() => { editor.chain().focus().toggleHeading({ level: 3 }).run() }}>Überschrift 3</MenuItem>
                </MenuList>
              </>
            )}
          </Menu>
          {hideDivider && (
            <Box borderRight="1px solid" borderRightColor="#e4e9ed"/>
          )}
        </Box>
      ),
    },
    {
      moduleName: 'link',
      module: (key, hideDivider) => (
        <Box key={key}>
          <Popover isOpen={editor.isActive('link') ? false : undefined}>
            <PopoverTrigger>
              <IconButton
                isActive={editor.isActive('link')}
                size="sm"
                variant="ghost"
                icon={<FiLink/>}
                aria-label="link"
                onClick={() => {
                  if (editor.isActive('link')) {
                    editor.chain().focus().unsetLink().run()
                  }
                }}
              />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow/>
              <PopoverCloseButton/>
              <PopoverHeader>Link Adresse</PopoverHeader>
              <PopoverBody>
                <form onSubmit={e => {
                  e.preventDefault()
                  const url = new FormData(e.currentTarget).get('url')?.toString()
                  if (url) {
                    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
                  }
                }}
                >
                  <InputGroup size="md">
                    <Input pr="4.5rem" type="text" name="url" placeholder="Link" variant="filled"/>
                    <InputRightElement width="4.5rem">
                      <Button type="submit" h="1.75rem" size="sm" colorScheme="blue">
                        Okay
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                </form>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          {hideDivider && (
            <Box borderRight="1px solid" borderRightColor="#e4e9ed"/>
          )}
        </Box>
      ),
    },
    {
      moduleName: 'textAlign',
      module: (key, hideDivider) => (
        <Box key={key}>
          <ButtonGroup
            size="sm"
            isAttached
            variant="ghost"
          >
            <IconButton
              icon={<FiAlignLeft/>}
              aria-label="left"
              isActive={editor.isActive({ textAlign: 'left' })}
              onClick={() => editor.chain().focus().setTextAlign('left').run()}
              size="sm"
            />
            <IconButton
              icon={<FiAlignCenter/>}
              aria-label="center"
              isActive={editor.isActive({ textAlign: 'center' })}
              onClick={() => editor.chain().focus().setTextAlign('center').run()}
              size="sm"
            />
            <IconButton
              icon={<FiAlignRight/>}
              aria-label="right"
              isActive={editor.isActive({ textAlign: 'right' })}
              onClick={() => editor.chain().focus().setTextAlign('right').run()}
              size="sm"
            />
            <IconButton
              icon={<FiAlignJustify/>}
              aria-label="center"
              isActive={editor.isActive({ textAlign: 'justify' })}
              onClick={() => editor.chain().focus().setTextAlign('justify').run()}
              size="sm"
            />
          </ButtonGroup>
          {hideDivider && (
            <Box borderRight="1px solid" borderRightColor="#e4e9ed"/>
          )}
        </Box>
      ),
    },
    {
      moduleName: 'figure',
      module: (key, hideDivider) => (
        <Box key={key}>
          <IconButton
            icon={<BsCardImage/>}
            aria-label="Add figure with caption"
            onClick={() => editor.chain().focus().setFigureImage({ src: '', caption: '' }).run()}
            size="sm"
            variant="ghost"
          />
          {hideDivider && (
            <Box borderRight="1px solid" borderRightColor="#e4e9ed"/>
          )}
        </Box>
      ),
    },
  ]

  const filteredModules = modules.filter(({ moduleName }) => (enabledModules.includes(moduleName) || enabledModules.includes('all')))

  return (
    <Flex className="floating-menu" gap="2" w="100%" p="2" borderBottom={showBorderBottom ? '1px solid' : undefined} borderBottomColor="#e4e9ed">
      {filteredModules.map(({ moduleName, module }, index) => {
        return module(`${moduleName}-${index}`, !(index === filteredModules.length - 1))
      })}
    </Flex>
  )
}

export default EditorMenu
