import { Box, ListItem, UnorderedList } from "@chakra-ui/react";
import type React from "react";
import { useEffect, useRef, useState } from "react";

interface PickerProps {
  options: string[];
  selectedValue: string;
  onChange: (value: string) => void;
}

const Picker: React.FC<PickerProps> = ({
  options,
  selectedValue,
  onChange,
}) => {
  const [scrollIndex, setScrollIndex] = useState(
    options.indexOf(selectedValue) || 0,
  )
  const containerRef = useRef<HTMLUListElement>(null)
  const optionRefs = useRef<(HTMLLIElement | null)[]>([])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = optionRefs.current.findIndex((ref) => ref === entry.target)
            if (index !== -1) {
              onChange(options[index])
            }
          }
        })
      },
      {
        root: containerRef.current,
        threshold: 0.5,
      },
    )

    // Observe only the center item
    const centerIndex = Math.floor(options.length / 2)
    const centerRef = optionRefs.current[centerIndex]
    if (centerRef) {
      observer.observe(centerRef)
    }

    return () => observer.disconnect()
  }, [options, onChange])

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: scrollIndex * (containerRef.current.offsetHeight / 5),
        behavior: "smooth",
      })
    }
  }, [scrollIndex])

  return (
    <Box
      my="4"
      pos="relative"
      px="1rem"
      w="100%"
      placeContent="center"
      display="inline-grid"
      gridTemplateColumns="1fr"
      sx={{
        fontFeatureSettings: "'tnum'",
      }}
    >
      <UnorderedList
        ref={containerRef}
        w="100%"
        display="flex"
        flexDirection="column"
        gap="2"
        mx={0}
        textAlign="center"
        maxHeight="4em"
        overflowY="scroll"
        scrollSnapType="y mandatory"
        paddingBottom="3em"
        paddingTop="3em"
      >
        {options.map((option, index) => (
          <ListItem
            key={index}
            borderRadius="xl"
            display="flex"
            listStyleType="none"
            ref={(el) => { optionRefs.current[index] = el }}
            scrollSnapAlign="center"
            height="100px"
            textAlign="center"
            mx={3}
            width="calc(100% - (var(--chakra-space-3) * 2))"
            bg="#e4e9ed"
          >
            {option}
          </ListItem>
        ))}
      </UnorderedList>
    </Box>
  )
}

export default Picker