import { animate, motion, useMotionValue, useTransform } from "framer-motion"
import { useEffect } from "react"

const AnimatedText = ({text, delay}: {text: string, delay: number}) => {
  const baseText = text
  const count = useMotionValue(0)
  const rounded = useTransform(count, (latest) => Math.round(latest))
  const displayText = useTransform(rounded, (latest) =>
    baseText.slice(0, latest),
  )

  useEffect(() => {
    const controls = animate(count, baseText.length, {
      type: "tween",
      delay,
      duration: 2.5,
      ease: "easeInOut",
    })
    return controls.stop
  }, [])


  return (
    <span className="">
      <motion.span>{displayText}</motion.span>
    </span>
  )
}


export default AnimatedText
