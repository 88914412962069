import type { CSSProperties } from "react"

interface IconlyIconProps {
    size?:number | string;
    style?: CSSProperties;
}

export const IconlyMenu = ({ size = 24, style }: IconlyIconProps) => {
    return (
      <svg style={style} width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.2208 18.0357H12.1568M12.2071 18.295C12.063 18.295 11.9463 18.1782 11.9463 18.0341C11.9463 17.8901 12.063 17.7734 12.2071 17.7734C12.3511 17.7734 12.4678 17.8901 12.4678 18.0341C12.4678 18.1782 12.3511 18.295 12.2071 18.295Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.2208 12.0142H12.1568M12.2071 12.2735C12.063 12.2735 11.9463 12.1567 11.9463 12.0127C11.9463 11.8686 12.063 11.752 12.2071 11.752C12.3511 11.752 12.4678 11.8686 12.4678 12.0127C12.4678 12.1567 12.3511 12.2735 12.2071 12.2735Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.2208 5.99276H12.1568M12.2071 6.25203C12.063 6.25203 11.9463 6.13519 11.9463 5.99117C11.9463 5.84715 12.063 5.73047 12.2071 5.73047C12.3511 5.73047 12.4678 5.84715 12.4678 5.99117C12.4678 6.13519 12.3511 6.25203 12.2071 6.25203Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.2584 18.0357H18.1944M18.2447 18.295C18.1006 18.295 17.9839 18.1782 17.9839 18.0341C17.9839 17.8901 18.1006 17.7734 18.2447 17.7734C18.3887 17.7734 18.5054 17.8901 18.5054 18.0341C18.5054 18.1782 18.3887 18.295 18.2447 18.295Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.2584 12.0142H18.1944M18.2447 12.2735C18.1006 12.2735 17.9839 12.1567 17.9839 12.0127C17.9839 11.8686 18.1006 11.752 18.2447 11.752C18.3887 11.752 18.5054 11.8686 18.5054 12.0127C18.5054 12.1567 18.3887 12.2735 18.2447 12.2735Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.2584 5.99276H18.1944M18.2447 6.25203C18.1006 6.25203 17.9839 6.13519 17.9839 5.99117C17.9839 5.84715 18.1006 5.73047 18.2447 5.73047C18.3887 5.73047 18.5054 5.84715 18.5054 5.99117C18.5054 6.13519 18.3887 6.25203 18.2447 6.25203Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.26768 18.0357H6.2037M6.25393 18.295C6.10991 18.295 5.99316 18.1782 5.99316 18.0341C5.99316 17.8901 6.10991 17.7734 6.25393 17.7734C6.39795 17.7734 6.51469 17.8901 6.51469 18.0341C6.51469 18.1782 6.39795 18.295 6.25393 18.295Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.26768 12.0142H6.2037M6.25393 12.2735C6.10991 12.2735 5.99316 12.1567 5.99316 12.0127C5.99316 11.8686 6.10991 11.752 6.25393 11.752C6.39795 11.752 6.51469 11.8686 6.51469 12.0127C6.51469 12.1567 6.39795 12.2735 6.25393 12.2735Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.26768 5.99276H6.2037M6.25393 6.25203C6.10991 6.25203 5.99316 6.13519 5.99316 5.99117C5.99316 5.84715 6.10991 5.73047 6.25393 5.73047C6.39795 5.73047 6.51469 5.84715 6.51469 5.99117C6.51469 6.13519 6.39795 6.25203 6.25393 6.25203Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    ) 
}