import { sbAsset } from 'helpers/asset'
import { toast } from 'react-toastify'
import { createClient } from 'utils/supabase/component'

type UserImageType = 'avatar' | 'banner'
type PostImageType = 'title' | 'gallery' | 'figure'

export async function handleFileUpload (file: File, mode: 'teams', relevantId: number): Promise<string>
export async function handleFileUpload (file: File, mode: 'sponsor', relevantId: number): Promise<string>
export async function handleFileUpload (file: File, mode: 'posts', relevantId: string, type: PostImageType): Promise<string>
export async function handleFileUpload (file: File, mode: 'trainer' | 'user' | 'boardmember', relevantId: string, type: UserImageType,): Promise<undefined>
export async function handleFileUpload (file: File, mode: 'trainer' | 'user' | 'posts' | 'teams' | 'boardmember' | 'sponsor' = 'user', relevantId: string | number, type?: UserImageType | PostImageType): Promise<string | undefined> {
  const toastId = toast.loading('Bild wird hochgeladen')
  const supabase = await createClient()
  switch (mode) {
    case 'boardmember':
    case 'trainer':
    case 'user': {
      const { data, error } = await supabase
        .storage
        .from('profiles')
        .upload(`${relevantId}/${mode}/${file.name}`, file, {
          cacheControl: '3600',
          upsert: true,
        })
      if (error) {
        toast.update(toastId, {
          render: 'Fehler beim hochladen des Bildes',
          type: 'error',
          autoClose: 5000,
          isLoading: false,
        })
      } else {
        toast.update(toastId, {
          render: 'Bild erfolgreich hochgeladen',
          type: 'success',
          autoClose: 5000,
          isLoading: false,
        })
        if (type === 'avatar') {
          switch (mode) {
            case 'trainer':
              await supabase
                .from('trainer')
                .update({ avatar_url: sbAsset(`profiles/${data.path}`) })
                .eq('profile_id', relevantId as string)
              break
            case 'user':
              await supabase
                .from('profiles')
                .update({ avatar_url: sbAsset(`profiles/${data.path}`) })
                .eq('user_id', relevantId as string)
              break
          }
        } else {
          switch (mode) {
            case 'boardmember':
              await supabase
                .from('boardmembers')
                .update({ banner_url: sbAsset(`profiles/${data.path}`) })
                .eq('profile_id', relevantId as string)
              break
            case 'trainer':
              await supabase
                .from('trainer')
                .update({ banner_url: sbAsset(`profiles/${data.path}`) })
                .eq('profile_id', relevantId as string)
              break
            case 'user':
              await supabase
                .from('profiles')
                .update({ banner_url: sbAsset(`profiles/${data.path}`) })
                .eq('user_id', relevantId as string)
              break
          }
        }
      }
      return undefined
    }
    case 'posts': {
      if (type === 'figure') {
        const tempPath = `temp/${file.name}`
        const { data, error } = await supabase.storage
          .from('posts')
          .upload(tempPath, file, {
            cacheControl: '3600',
            upsert: true,
          })

        if (error) {
          toast.update(toastId, {
            render: 'Fehler beim hochladen des Bildes',
            type: 'error',
            autoClose: 5000,
            isLoading: false,
          })
        } else {
          toast.update(toastId, {
            render: 'Bild erfolgreich hochgeladen',
            type: 'success',
            autoClose: 5000,
            isLoading: false,
          })
          return sbAsset(`posts/${data.path}`)
        }
        break
      }

      const { data, error } = await supabase.storage
        .from('posts')
        .upload(`${relevantId}/${type}/${file.name}`, file, {
          cacheControl: '3600',
          upsert: true,
        })
      if (error) {
        toast.update(toastId, {
          render: 'Fehler beim hochladen des Bildes',
          type: 'error',
          autoClose: 5000,
          isLoading: false,
        })
      } else {
        toast.update(toastId, {
          render: 'Bild erfolgreich hochgeladen',
          type: 'success',
          autoClose: 5000,
          isLoading: false,
        })
        return sbAsset(`posts/${data.path}`)
      }
      break
    }
    case 'teams': {
      const { data, error } = await supabase.storage.from('teams').upload(`${relevantId}/${file.name}`, file, {
        cacheControl: '3600',
        upsert: true,
      })
      if (error) {
        toast.update(toastId, {
          render: 'Fehler beim hochladen des Bildes',
          type: 'error',
          autoClose: 5000,
          isLoading: false,
        })
      } else {
        toast.update(toastId, {
          render: 'Bild erfolgreich hochgeladen',
          type: 'success',
          autoClose: 5000,
          isLoading: false,
        })
        return sbAsset(`teams/${data.path}`)
      }
      break
    }
    case 'sponsor': {
      const { data, error } = await supabase.storage.from('sponsors').upload(`${relevantId}/${file.name}`, file, {
        cacheControl: '3600',
        upsert: true,
      })
      if (error) {
        toast.update(toastId, {
          render: 'Fehler beim hochladen des Bildes',
          type: 'error',
          autoClose: 5000,
          isLoading: false,
        })
      } else {
        toast.update(toastId, {
          render: 'Bild erfolgreich hochgeladen',
          type: 'success',
          autoClose: 5000,
          isLoading: false,
        })
        return sbAsset(`sponsors/${data.path}`)
      }
    }
  }
}
