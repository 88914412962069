'use client'
import { Box, Heading } from '@chakra-ui/react'
import type { getSponsors } from '@hooks/sponsors'
import type { SponsorCarouselStoryblok } from '@lib/storyblok.types'
import Image from 'next/image'
import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Autoplay, Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
interface IProps {
  blok: SponsorCarouselStoryblok
  sponsors: Awaited<ReturnType<typeof getSponsors>>
}

const SponsorCarousel: React.FC<IProps> = ({ blok, sponsors }) => {
  return (
    <Box w="85%" mx="auto">
      <Heading pb="5" fontWeight="semibold" fontSize="2xl" as="h2">{blok.title}</Heading>
      <Box w="100%">
        <Swiper
          loop={true}
          lazyPreloadPrevNext={1}
          navigation
          autoplay
          pagination
          modules={[Navigation, Pagination, Autoplay]}
        >
          {sponsors.map((sponsor, index) => (
            <SwiperSlide key={`${String(sponsor.created_at)}-${String(index)}`}>
              <Box maxW="60%" mx="auto" pos="relative" w="100%" h="450px" >
                <Image src={sponsor.image!} alt={sponsor.name!} fill objectFit="contain"/>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </Box>
    </Box>
  )
}

export default SponsorCarousel
