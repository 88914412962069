import { Box, Text, css, useDisclosure, } from '@chakra-ui/react'
import { editorExtensions } from '@components/Editor/Editor'
import { Image } from '@components/Image'
import { Motion } from '@components/motion'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { generateText } from '@tiptap/react'
import type { getTeams } from 'hooks/teams'
import NextLink from 'next/link'
import type React from 'react'
import { useMemo } from 'react'

interface ITeamCardProps {
  team: Awaited<ReturnType<typeof getTeams>>[0]
  href: string
  draggable?: boolean
}

const variants = {
  open: {
    x: -500,
  },
  closed: {
    x: 0,
  },
}
const overlayVariants = {
  open: {
    opacity: 1,
  },
  closed: {
    opacity: 0,
  },
}

const TeamCard: React.FC<ITeamCardProps> = ({ team, href, draggable }) => {
  console.log(team)

  const parsedContent = useMemo(() => {
    if (!team.description || team.description === '{}') return ''
    return generateText(JSON.parse(team.description), editorExtensions)
  }, [team.description])
  const { onOpen, isOpen, onClose } = useDisclosure()


  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: team.id})

  const content = useMemo(() => {
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    }

    if (draggable) {
      return (
        <Box
          ref={setNodeRef}
          style={style}
          {...attributes}
          {...listeners}
          cursor="grab"
          userSelect="none"
          pos="relative"
          key={team.id}
          w="100%"
          h="260px"
          borderRadius="xl"
          overflow="hidden"
        >
          <Image src={team.image ?? ''} alt={team.name} fill objectFit="cover" pos="absolute"/>
          <Motion.Box
            maxW="calc(100% - var(--chakra-space-3) - var(--chakra-space-3))"
            pos="absolute"
            borderRadius={20}
            bottom="3"
            left="3"
            px="2"
            py={1}
            bg="white"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {team.name}
          </Motion.Box>
        </Box>
)
    }
    return (
      <NextLink href={href}>
        <Box
          cursor="pointer"
          userSelect="none"
          pos="relative"
          key={team.id}
          w="100%"
          h="260px"
          borderRadius="xl"
          overflow="hidden"
          onMouseEnter={onOpen}
          onMouseLeave={onClose}
        >
          <Image src={team.image ?? ''} alt={team.name} fill objectFit="cover" pos="absolute"/>
          <Motion.Box
            animate={isOpen ? 'open' : 'closed'}
            variants={overlayVariants}
            bg="blackAlpha.700"
            pos="absolute"
            bottom="0"
            left="0"
            w="100%"
            h="100%"
            borderRadius="xl"
            p={3}
          >
            <Text color="white" fontWeight="bold" fontSize="1.1em">{team.name}</Text>
            <Text
              color="white"
              css={css`
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3; /* number of lines to show */
        line-height: var(--chakra-lineHeights-base);        /* fallback */
        max-height: var(--chakra-lineHeights-base) * 3;       /* fallback */
              `}
            >{parsedContent}
            </Text>
          </Motion.Box>
          <Motion.Box
            layout
            animate={isOpen ? 'open' : 'closed'}
            variants={variants}
            maxW="calc(100% - var(--chakra-space-3) - var(--chakra-space-3))"
            pos="absolute"
            borderRadius={20}
            bottom="3"
            left="3"
            px="2"
            py={1}
            bg="white"
            // wordBreak="keep-all"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {team.name}
          </Motion.Box>
        </Box>
      </NextLink>
  )
  }, [attributes, draggable, href, isOpen, listeners, onClose, onOpen, parsedContent, setNodeRef, team.id, team.image, team.name, transform, transition])

  return (
    content
  )
}

export default TeamCard
