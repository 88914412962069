'use client'
import type { Editor } from '@tiptap/react'
import { BubbleMenu, FloatingMenu } from '@tiptap/react'
import BubbleMenuContent from './BubbleMenu'
import type { TModule } from './EditorMenu'
import EditorMenu from './EditorMenu'

interface IEditorProps {
  editor?: Editor | null
  enabledModules?: TModule[]
}
const EditorMenus = ({ editor, enabledModules }: IEditorProps) => {
  if(!editor) return null
  return (
    <>
      <FloatingMenu editor={editor}>
        <EditorMenu 
          editor={editor} 
          enabledModules={[
            'format', 
            'figure',
            ...(enabledModules?.includes('textAlign') ? (['textAlign'] as TModule[]) : []),
          ]}
        />
      </FloatingMenu>
      <BubbleMenu editor={editor}>
        <BubbleMenuContent editor={editor}/>
      </BubbleMenu>
    </>
  )
}

export default EditorMenus
