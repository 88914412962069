'use client'
import { Box, Input, Spinner } from '@chakra-ui/react'
import { handleFileUpload } from '@components/UserEditor/helpers'
import { NodeViewWrapper } from '@tiptap/react'
import { useUser } from 'hooks/user'
import { useCallback, useState } from 'react'

export const FigureImageComponent = ({ node, updateAttributes }) => {
  const [isUploading, setIsUploading] = useState(false)
  const { data: user } = useUser()

  const handleImageUpload = useCallback(async (file: File) => {
    setIsUploading(true)
    try {
      const imagePath = await handleFileUpload(
        file, 
        'posts',
        'temp', // This will be replaced with actual post ID when saving
        'figure',
      )
      
      if (imagePath) {
        updateAttributes({
          src: imagePath,
          isTemp: true,
          originalName: file.name,
        })
      }
    } catch (error) {
      console.error('Failed to upload image:', error)
    } finally {
      setIsUploading(false)
    }
  }, [updateAttributes])

  return (
    <NodeViewWrapper>
      <figure>
        <Box position="relative" minH="200px">
          {isUploading && (
            <Box position="absolute" inset={0} display="flex" alignItems="center" justifyContent="center">
              <Spinner/>
            </Box>
          )}
          {node.attrs.src ? (
            <img 
              src={node.attrs.src} 
              alt={node.attrs.caption || ''} 
              style={{ maxWidth: '100%' }}
            />
          ) : (
            <Input 
              type="file" 
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0]
                if (file) handleImageUpload(file)
              }}
              disabled={isUploading}
            />
          )}
        </Box>
        <figcaption>
          <Input
            placeholder="Bildunterschrift hinzufügen..."
            value={node.attrs.caption}
            onChange={(e) => updateAttributes({ caption: e.target.value })}
            size="sm"
            variant="flushed"
          />
        </figcaption>
      </figure>
    </NodeViewWrapper>
  )
} 