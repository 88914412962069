'use client'
import { Flex, Grid, Text, useDisclosure } from "@chakra-ui/react"
import AnimatedText from "@components/AnimatedText/AnimatedText"
import { MotionBox, MotionDivider, MotionFlex, MotionGridItem, MotionHeading, MotionIconButton, MotionText } from "@components/motion"
import type { getTrainers } from "@hooks/trainers"
import type { TrainersStoryblok } from "@lib/storyblok.types"
import type { Variants } from "framer-motion"
import type { NextPage } from "next"
import Image from 'next/image'
import { useState } from "react"
import { BsFacebook, BsInstagram, BsTwitterX } from "react-icons/bs"
import { FiMail, FiPhone } from "react-icons/fi"

type Trainer = Awaited<ReturnType<typeof getTrainers>>[0]

interface IProps {
  blok: TrainersStoryblok
  trainers: Awaited<ReturnType<typeof getTrainers>>
}
const iconContainerVariants: Variants = {
  open: {
    transition: {
      delayChildren: .8,
      staggerChildren: .1,
    },
  },
}
const iconButtonVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 500,
      damping: 17,
      mass: 2,
    },
  },
  closed: {
    opacity: 0,
    y: 25,
    transition: {
      type: 'spring',
      stiffness: 500,
      damping: 17,
      mass: 2,
    },
  },
}
const variants: Variants = {
  open: {
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 20,
      staggerChildren: .2,
    },
  },
  closed: {
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 20,
    },
  },
}

const TrainerGridDisplay: NextPage<IProps> = ({blok, trainers}) => {
  const [activeTrainer, setActiveTrainer] = useState<Trainer | null>(null)
  const {onOpen, onClose, isOpen} = useDisclosure()



  return (
    <Grid
      gap="5"
      templateColumns="repeat(auto-fill, minmax(225px, 1fr))"
    >
      {trainers.map(trainer => (
        <MotionGridItem
          as={Flex}
          key={trainer.profile_id}
          bg="white"
          cursor="pointer"
          layout="position"
          borderRadius="xl"
          layoutId={`trainer-${trainer.profile_id}`}
          p={4}
          gap="3"
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          whileHover={{ scale: 1.15 }}
          variants={variants}
          initial="closed"
          animate={isOpen ? 'open' : 'closed'}
          onClick={() => {
            setActiveTrainer(trainer)
            onOpen()
          }}
        >
          <MotionFlex>
            <MotionBox
              layout="position"
              layoutId={`avatar-${trainer.profile_id}`}
              pos="relative"
              w="80px"
              h="80px"
              overflow="hidden"
              borderRadius="full"
          //@ts-expect-error type overlap
              transition={{ duration: .3 }}
            >
              <Image
                fill
                src={trainer.avatar_url
                ?? trainer.profile.avatar_url
                ?? `https://ui-avatars.com/api/?name=${trainer.profile.first_name}+${trainer.profile.last_name}`}
                alt={`${trainer.profile.first_name} ${trainer.profile.last_name}`}
              />
            </MotionBox>
          </MotionFlex>
          <MotionHeading
            as="h3"
            fontSize="lg"
            fontWeight="semibold"
          >
            {trainer.profile.first_name} {trainer.profile.last_name}
          </MotionHeading>
          {trainer.teams.map((team, i) => (
            <Text key={`${team.name  }-${i}`} fontSize="small" fontWeight="semilight">
              {team.name}
              {(i + 1 < trainer.teams.length) && (
                '\u00A0\u2022\u00A0'
              )}
            </Text>
            
          ))}
        </MotionGridItem>
      ))}
      {activeTrainer && (
        <MotionFlex
          justify="center"
          align="center"
          w="100dvw"
          h="100dvh"
          backdropFilter="blur(3px)"
          top="0"
          right="0"
          left="0"
          bottom="0"
          zIndex={100}
          pos="fixed"
          onClick={() => {
            setActiveTrainer(null)
            onClose()
          }}
        >
          <MotionFlex
            onClick={e => { e.stopPropagation() }}
            align="flex-start"
            layout="position"
            p="5"
            flexDir="column"
            layoutId={`trainer-${activeTrainer.profile_id}`}
            w={['80dvw', '800px']}
            h={['80dvh', '900px']}
            borderRadius="xl"
            border="1px solid"
            borderColor="gray.300"
            bg="white"
          >
            <MotionFlex
              flexDir="row"
              gap="4"
              w="100%"
              align="center"
              borderRadius="xl"
              bgColor="black"
              bgImage={`url(${activeTrainer.banner_url})`}
              bgSize="cover"
              minH="150px"
              bgPosition="center"
              bgRepeat="no-repeat"
              pos="relative"
            >
              <MotionBox
                layout="position"
                border="8px solid white"
                layoutId={`avatar-${activeTrainer.profile_id}`}
                pos="absolute"
                w="120px"
                h="120px"
                left="50%"
                top="calc(50% + 16px)"
                overflow="hidden"
                transformTemplate={(_, generatedString) => {
                  return `translateX(-50%) ${generatedString}`
                }}
                borderRadius="full"
              >
                <Image
                  fill
                  src={activeTrainer.avatar_url
                    ?? activeTrainer.profile.avatar_url
                    ?? `https://ui-avatars.com/api/?name=${activeTrainer.profile.first_name}+${activeTrainer.profile.last_name}`}
                  alt={`${activeTrainer.profile.first_name} ${activeTrainer.profile.last_name}`}
                />
              </MotionBox>
            </MotionFlex>
            <Flex
              w="100%"
              justifyContent="space-between" 
              mt="55px"
              flexWrap="wrap"
              flexDir={['column', 'row']}
            >
              <MotionFlex
                flexDir="row"
                fontWeight="900"
                fontSize="xxx-large"
                bgColor="white"
                borderRadius="xl"
                justify="center"
                align="center"
                px="2"
                py="1"
              >
                <MotionHeading
                  as="h3"
                  initial={{opacity: 0, x: -20}}
                  animate={{opacity: 1, x: 0}}
                  w="fit-content"
                  display="inline-flex"
                    // @ts-expect-error type overlap
                  transition={{type: 'spring', stiffness: 400, damping: 15, delay: .4}}
                >
                  {activeTrainer.profile.first_name}{'\u00A0'}
                </MotionHeading>
                <MotionHeading
                  as="h3"
                  w="fit-content"
                  initial={{opacity: 0, x: -20}}
                  animate={{opacity: 1, x: 0}}
                    // @ts-expect-error type overlap
                  transition={{type: 'spring', stiffness: 400, damping: 15, delay: .5}}
                >
                  {activeTrainer.profile.last_name}
                </MotionHeading>
              </MotionFlex>
              <MotionFlex
                variants={iconContainerVariants}
                initial="closed"
                animate={isOpen ? 'open' : 'closed'}
                flexDir="row"
                justify={['center', 'flex-end']}
              >
                <MotionIconButton
                  variant="ghost"
                  icon={<FiPhone/>}
                  aria-label="Phone"
                  as="a"
                  variants={iconButtonVariants}
                  //@ts-expect-error type overlap
                  href={`tel:${activeTrainer.phone}`}
                />
                <MotionIconButton
                  variant="ghost"
                  icon={<FiMail/>}
                  aria-label="Email"
                  as="a"
                  variants={iconButtonVariants}
                  //@ts-expect-error type overlap
                  href={`mailto:${activeTrainer.email}`}
                />
                {(activeTrainer.x_handle || activeTrainer.instagram_handle || activeTrainer.facebook_link) && (
                  <MotionDivider
                    variants={iconButtonVariants}
                    orientation="vertical"
                    borderLeftColor="gray.900"
                    mx="6px"
                    h="40px"
                  />
                )}
                {activeTrainer.x_handle && (
                  <MotionIconButton
                    variant="ghost"
                    as="a"
                    variants={iconButtonVariants}
                    //@ts-expect-error type overlap
                    href={`https://twitter.com/${activeTrainer.x_handle}`}
                    icon={<BsTwitterX/>}
                    aria-label="Twitter"
                  />
                )}
                {activeTrainer.instagram_handle && (
                  <MotionIconButton
                    variant="ghost"
                    as="a"
                    variants={iconButtonVariants}
                    //@ts-expect-error type overlap
                    href={`https://instagram.com/${activeTrainer.instagram_handle}`}
                    icon={<BsInstagram/>}
                    aria-label="Instagram"
                  />
                )}
                {activeTrainer.facebook_link && (
                  <MotionIconButton
                    variant="ghost"
                    as="a"
                    variants={iconButtonVariants}
                    //@ts-expect-error type overlap
                    href={activeTrainer.facebook_link}
                    icon={<BsFacebook/>}
                    aria-label="Facebook"
                  />
                )}
              </MotionFlex>
            </Flex>
            <MotionText
              px="2"
              fontWeight="200"
              initial={{opacity: 0, y: 20}}
              animate={{opacity: 1, y: 0}}
              // @ts-expect-error type overlap
              transition={{type: 'spring', stiffness: 400, damping: 15, delay: .6}}
            >
              {activeTrainer.qualification}
            </MotionText>
            <MotionText
              px="2"
              py="2"
              initial={{opacity: 0, y: 20}}
              animate={{opacity: 1, y: 0}}
             // @ts-expect-error type overlap
              transition={{type: 'spring', stiffness: 400, damping: 15, delay: .8}}
            >
              <AnimatedText delay={.8} text={activeTrainer.additional_info ?? ''}/>
            </MotionText>
          </MotionFlex>
        </MotionFlex>
      )}
    </Grid>
  )
}

export default TrainerGridDisplay